import type {
  ConnectionType,
  SignalRGenericMessageType,
  EmployeeActivityType,
  SystemServiceType,
  ListType,
  OrderStatusType,
  CarrierAccountOwnerType,
  PackingMaterialType,
  PackingMaterialMadeFromType,
  ReceiptPrintLabelsType,
  UserType,
  AppointmentFulfillmentType,
  WarehouseAssetConditionType,
  AppointmentStatusType,
  TruckingJobStatusType,
  MoveableUnitStatusType,
} from '../types';

export const envBuildsVariables = {
  PRODUCTION: 'production',
  STAGING: 'staging',
  DEVELOPMENT: 'development',
};

export const isProduction =
  import.meta.env.MODE === envBuildsVariables.PRODUCTION;

export const isDev = import.meta.env.MODE === envBuildsVariables.DEVELOPMENT;

export const scanModes = {
  PICK_AND_STICK: 'pickAndStick',
  PICK_AND_PACK: 'pickAndPack',
};

export const freightModes = {
  PICK_AND_SHIP: 'PickAndShip',
  PREP_PALLET: 'PrepPallet',
};

export const packageCollectionTypes = {
  ITEMS: 'items',
  MATERIAL: 'material',
  METADATA: 'metadata',
};

export const orderStatuses = {
  PENDING: 'Pending',
  PICKED: 'Picked',
  PROCESSING: 'Processing',
  CLOSED: 'Closed',
  PACKED: 'Packed',
  HOLD: 'Hold',
  STORE_IN_BIN: 'StoredInBin',
  CANCELED: 'Canceled',
  PAUSED: 'Paused',
  PACKED_HOLD: 'PackedHold',
} satisfies Record<string, OrderStatusType>;

export const truckingJobStatuses = {
  PENDING: 'Pending',
  SENT_TO_CUSTOMER: 'SentToCustomer',
  AWAITING_SCHEDULING: 'AwaitingScheduling',
  SCHEDULED: 'Scheduled',
  CLOSED: 'Closed',
  CANCELED: 'Cancelled',
  QUOTE_ERROR: 'QuoteError',
  NOT_USED: 'NotUsed',
} satisfies Record<string, TruckingJobStatusType>;

export const packageItemTypes = {
  MATERIAL: 'material',
  ORDER_ITEM: 'item',
};

export const unitOfMeasure = {
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
  MASTER: 'Master',
};

export const packingMaterialTypes = {
  BOX: 'Box',
  TAPE: 'Tape',
  VOID_FILL: 'VoidFill',
  PALLET: 'Pallet',
  MAILER: 'Mailers',
  LABELS: 'Labels',
  BIN: 'Bin',
  CRATE: 'Crate',
} satisfies Record<string, PackingMaterialType>;

export const packingMaterialMadeFromTypes = {
  WOOD: 'Wood',
  PLASTIC: 'Plastic',
} satisfies Record<string, PackingMaterialMadeFromType>;

export const carrierImages = {
  DHL_ECOMMERCE: 'https://storage.googleapis.com/3pl-public/dhl-ecommerce.png',
  DHL_EXPRESS: 'https://storage.googleapis.com/3pl-public/dhl-express.png',
  ENDICIA: 'https://storage.googleapis.com/3pl-public/endicia.png',
  FEDEX: 'https://storage.googleapis.com/3pl-public/fedex.png',
  FEDEX_FREIGHT: 'https://storage.googleapis.com/3pl-public/fedex-freight.png',
  FEDEX_UK: 'https://storage.googleapis.com/3pl-public/fedex-uk.png',
  STAMPS_DOT_COM: 'https://storage.googleapis.com/3pl-public/stamps.com.png',
  UPS: 'https://storage.googleapis.com/3pl-public/ups.png',
  ON_TRAC: 'https://storage.googleapis.com/3pl-public/on-trac.png',
  ESTES_EXPRESS:
    'https://storage.googleapis.com/3pl-public/estes-express-lines.png',
  DAY_LIGHT: 'https://storage.googleapis.com/3pl-public/daylight.png',
  CLEAR_LANE: 'https://storage.googleapis.com/3pl-public/clear-Lane-logo.png',
  OLD_DOMINION:
    'https://storage.googleapis.com/3pl-public/old-dominion-logo.svg',
  SAIA: 'https://storage.googleapis.com/3pl-public/Saia.png',
  JP_EXPRESS: 'https://storage.googleapis.com/3pl-public/JP_Express.svg',
  UPS_FREIGHT: 'https://storage.googleapis.com/3pl-public/UPS-freight.png',
  USPS: 'https://storage.googleapis.com/3pl-public/usps-logo.png',
  AMAZON_SHIPPING:
    'https://storage.googleapis.com/3pl-public/amazon-shipping-logo.png',
  WARD: 'https://storage.googleapis.com/3pl-public/ward-logo.png',
  TFORCE_FREIGHT: 'https://storage.googleapis.com/3pl-public/tforce-logo.png',
  XPO: 'https://storage.googleapis.com/3pl-public/xpo-logo1.png',
  PITT_OHIO: 'https://storage.googleapis.com/3pl-public/pitt-ohio.png',
  GLOBAL_TRANZ: 'https://storage.googleapis.com/3pl-public/global-tranz.png',
  ABF_FREIGHT: 'https://storage.googleapis.com/3pl-public/abf-freight-logo.png',
  R_L_CARRIERS:
    'https://storage.googleapis.com/3pl-public/R-L-Carriers-logo.png',
};

// cspell:disable
export const carrierImagesObj = {
  stamps_com: { url: carrierImages.STAMPS_DOT_COM, width: 80, height: 24 },
  stamps: { url: carrierImages.STAMPS_DOT_COM, width: 80, height: 24 },
  ups: { url: carrierImages.UPS, width: 35, height: 36 },
  fedex: { url: carrierImages.FEDEX, width: 75, height: 24 },
  dhl_ecommerce: { url: carrierImages.DHL_ECOMMERCE, width: 75, height: 24 },
  dhl_global_mail: { url: carrierImages.DHL_ECOMMERCE, width: 75, height: 24 },
  dhl_express: { url: carrierImages.DHL_EXPRESS, width: 75, height: 24 },
  endicia: { url: carrierImages.ENDICIA, width: 75, height: 24 },
  usps: { url: carrierImages.USPS, width: 75, height: 24 },
  clear_lane: { url: carrierImages.CLEAR_LANE, width: 75, height: 24 },
  fxnl: { url: carrierImages.FEDEX_FREIGHT, width: 75, height: 24 },
  fxfe: { url: carrierImages.FEDEX_FREIGHT, width: 75, height: 24 },
  ontrac: { url: carrierImages.ON_TRAC, width: 63, height: 31 },
  exla: { url: carrierImages.ESTES_EXPRESS, width: 75, height: 24 },
  dylt: { url: carrierImages.DAY_LIGHT, width: 75, height: 44 },
  odfl: { url: carrierImages.OLD_DOMINION, width: 60, height: 44 },
  saia: { url: carrierImages.SAIA, width: 60, height: 44 },
  jpxs: { url: carrierImages.JP_EXPRESS, width: 75, height: 24 },
  upgf: { url: carrierImages.UPS_FREIGHT, width: 42, height: 40 },
  amzn_us: { url: carrierImages.AMAZON_SHIPPING, width: 80, height: 24 },
  ward: { url: carrierImages.WARD, width: 75, height: 26 },
  tfin: { url: carrierImages.TFORCE_FREIGHT, width: 75, height: 25 },
  cnwy: { url: carrierImages.XPO, width: 48, height: 17 },
  pitd: { url: carrierImages.PITT_OHIO, width: 75, height: 25 },
  gelj: { url: carrierImages.GLOBAL_TRANZ, width: 75, height: 25 },
  abfs: { url: carrierImages.ABF_FREIGHT, width: 75, height: 25 },
  rlca: { url: carrierImages.R_L_CARRIERS, width: 75, height: 13 },
};

export const carrierTrackingLinkObj = {
  stamps_com: (trackingNum: string) =>
    `http://www.stamps.com/shipstatus/submit/?confirmation=${trackingNum}`,
  stamps: (trackingNum: string) =>
    `http://www.stamps.com/shipstatus/submit/?confirmation=${trackingNum}`,
  ups: (trackingNum: string) =>
    `http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${trackingNum}`,
  fedex: (trackingNum: string) =>
    `https://www.fedex.com/fedextrack/?tracknumbers=${trackingNum}`,
  dhl_ecommerce: (trackingNum: string) =>
    `https://www.dhl.com/us-en/home/tracking.html?tracking-id=${trackingNum}`,
  dhl_global_mail: (trackingNum: string) =>
    `https://www.dhl.com/us-en/home/tracking.html?tracking-id=${trackingNum}`,
  dhl_express: (trackingNum: string) =>
    `https://www.dhl.com/us-en/home/tracking.html?tracking-id=${trackingNum}`,
  endicia: (trackingNum: string) =>
    `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${trackingNum}`,
  usps: (trackingNum: string) =>
    `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${trackingNum}`,
  ontrac: (trackingNum: string) =>
    `https://www.ontrac.com/tracking.asp?trackingres=submit&tracking_number=${trackingNum}&trackBtn.x=9&trackBtn.y=18`,
  exla: (trackingNum: string) =>
    `https://www.estes-express.com/myestes/tracking/shipments?type=PRO&query=${trackingNum}`,
  dylt: (trackingNum: string) =>
    `https://mydaylight.dylt.com/external/shipment?probill=${trackingNum}`,
  odfl: (trackingNum: string) =>
    `https://www.odfl.com/us/en/tools/trace-track-ltl-freight/trace.html?proNumbers=${trackingNum}`,
  saia: (trackingNum: string) =>
    `https://www.saia.com/track/details?pro=${trackingNum}`,
  //TODO: check that JP Express tracking link is correct
  jpxs: (trackingNum: string) =>
    `https://www.myjpexpress.com/umbraco/api/tracking/GetFreightBillDetails?freightSearchNumber=${trackingNum}&freightSearchType=FreightBillNumber`,
  amzn_us: (trackingNum: string) =>
    `https://track.amazon.com/tracking/${trackingNum}?trackingId=${trackingNum}`,
  ward: (proNumber: string) =>
    `https://wardtlctools.com/wardtrucking/traceshipment/create?pro=${proNumber}`,
  tfin: (proNumber: string) =>
    `https://tforcefreight.com/ltl/apps/Tracking?proNumbers=${proNumber}`,
  cnwy: (trackingNumber: string) =>
    `https://www.xpo.com/track/shipment/${trackingNumber}`,
  pitd: (trackingNumber: string) =>
    `https://pittohio.com/myPittOhio/Shipping/LTL/TraceRequest`, // They don't provide a way to embed the tracking number
  gelj: (trackingNumber: string) =>
    `https://carrierrate.globaltranz.com/PublicTrack/PublicTrack`, // They don't provide a way to embed the tracking number
  abfs: (trackingNumber: string) =>
    `https://view.arcb.com/nlo/tools/tracking/${trackingNumber}`,
  rlca: (trackingNumber: string) =>
    `https://www2.rlcarriers.com/freight/shipping/shipment-tracing`, // They don't provide a way to embed the tracking number
};

export const creditCardImages = {
  visa: 'https://storage.googleapis.com/3pl-public/visa.svg',
  mastercard: 'https://storage.googleapis.com/3pl-public/mastercard.svg',
  americanexpress: 'https://storage.googleapis.com/3pl-public/amex.svg',
  discover: 'https://storage.googleapis.com/3pl-public/discover.svg',
  diners: 'https://storage.googleapis.com/3pl-public/diners.svg',
  jcb: 'https://storage.googleapis.com/3pl-public/jcb.svg',
};

export const receiptTemplateDownloadLink =
  'https://storage.googleapis.com/3pl-public/3plify-Receipt-Import-Template.xlsx?v1'; // Added v1 for cache busting

// cspell:enable

export const feeTypes = {
  ProcessingFee: 'Order',
  FreightProcessingFee: 'Order',
  PickFee: 'Item',
  PalletFee: 'Pallet',
  DrayageFee: 'Shipping',
  ShipmentMarkupFee: 'Shipping',
};

export const roleTypes = {
  Admin: 'Admin',
  TruckingSalesman: 'TruckingSalesman',
  Bookkeeper: 'Bookkeeper',
  PackerProcessor: 'PackerProcessor',
  Customer: 'Customer',
};

export const userTypes = {
  Admin: 'Admin',
  CustomerService: 'CustomerService',
  Processor: 'Processor',
  Scanner: 'Scanner',
  Customer: 'Customer',
  TruckingSalesperson: 'TruckingSalesperson',
  DefaultCarrier: 'DefaultCarrier',
  ScannerProcessor: 'ScannerProcessor',
  ManageAppointment: 'ManageAppointment',
  AppointmentCheckIn: 'AppointmentCheckIn',
  Manager: 'Manager',
} satisfies Record<string, UserType>;

export const userCategories = {
  Internal: 'Internal',
  Customer: 'Customer',
};

export const shipEngineConfirmationTypes = [
  { id: 'None', name: 'None' },
  { id: 'Delivery', name: 'Delivery' },
  { id: 'Signature', name: 'Signature' },
  { id: 'AdultSignature', name: 'Adult Signature' },
  { id: 'DirectSignature', name: 'Direct Signature' },
  { id: 'DeliveryMailed', name: 'Delivery Mailed' },
];

export const shipEngineResidentialIndicatorTypes = [
  { id: 'unknown', name: 'Unknown' },
  { id: 'yes', name: 'Yes' },
  { id: 'no', name: 'No' },
];

export const connectionTypes = {
  DEVICES: 'devices',
  SYSTEM_ALERT: 'systemAlert',
  SYSTEM_MESSAGE: 'systemMessage',
} satisfies Record<string, ConnectionType>;

export const emailToOptions = [
  { id: 'Recipient', name: 'Recipient' },
  { id: 'Customer', name: 'Customer' },
];

export const smallParcelBillToPartyOptions = [
  { id: 'Recipient', name: 'Recipient' },
  { id: 'ThirdParty', name: 'Third Party' },
];

export const freightBillToPartyOptions = [
  { id: 'Recipient', name: 'Recipient' },
  { id: 'ThirdParty', name: 'Third Party' },
  { id: 'Prepaid', name: 'Prepaid' },
];

export const validOrderStatusForShipmentJob = [
  orderStatuses.PACKED,
  orderStatuses.STORE_IN_BIN,
];

export const clientMessageTypes = {
  SHIPMENT_LOAD_CHANGES: 'ShipmentLoadChange',
  SHIPMENT_LOAD_DISPATCHED: 'ShipmentLoadDispatched',
  SP_ORDER_PROCESSED: 'SmallParcelOrderProcessed',
  FREIGHT_ORDER_PROCESSED: 'FreightOrderProcessed',
  DRIVER_CHECKED_IN: 'DriverCheckedIn',
  APPOINTMENT_STATUS_CHANGED: 'AppointmentStatusChanged',
  APPOINTMENT_UNLOADED: 'AppointmentUnloaded',
} satisfies Record<string, SignalRGenericMessageType>;

export const dashboardGroupByTypes = { Week: 'Week', Month: 'Month' };

export const sortByOptions = [
  { id: 'CheapestToExpensive', name: 'Price low to high' },
  { id: 'FastestToSlowest', name: 'Fast to Slow' },
];

export const truckingJobTabs = {
  open: 'open',
  scheduled: 'scheduled',
};

export const truckingLoadTabs = {
  open: 'open',
  completed: 'completed',
};

export const drayageJobTabs = {
  open: 'open',
  completed: 'completed',
};

export const invoiceStatusTypes = {
  Open: 'Open',
  Partial: 'Partial',
  Paid: 'Paid',
  Error: 'Error',
  Void: 'Void',
};

export const creditCardProcessingFeeTypes = {
  VISA: 'VisaProcessingFee',
  MASTER_CARD: 'MastercardProcessingFee',
  AMERICAN_EXPRESS: 'AmexProcessingFee',
  DISCOVER: 'DiscoverProcessingFee',
  DINERS: 'DinersProcessingFee',
  JCB: 'JcbProcessingFee',
};

export const employeeActivityTypes = {
  UNKNOWN: 'Unknown',
  ORDER_PROCESSING: 'OrderProcessing',
  PALLET_STORING: 'PalletStoring',
  PALLET_PULLING: 'PalletPulling',
  PALLET_DISPATCHING: 'PalletDispatching',
  MANUAL_ACTIVITY: 'ManualActivity',
  UNLOAD: 'Unload',
  PREP_RECEIPTS: 'PrepReceipts',
  TRANSPORT_RECEIPTS: 'TransportReceipts',
  PUT_AWAY_RECEIPTS: 'PutAwayReceipts',
  MOVE_INVENTORY: 'MoveInventory',
  ORDER_PICK: 'OrderPick',
} satisfies Record<string, EmployeeActivityType>;

export const systemServiceTypes = {
  ORDER_FULFILLMENT: 'OrderFulfillment',
  TRUCKING_JOB: 'TruckingJob',
  DRAYAGE_JOB: 'DrayageJob',
  RESELLER_JOB: 'ResellerJob',
  PURCHASE_LABEL: 'LabelPurchasing',
  RECEIPT: 'Receipt',
  APPOINTMENT: 'Appointment',
  FULFILLED_SERVICES: 'Manual',
} satisfies Record<string, SystemServiceType>;

export const listTypes = {
  appointments: 'Appointments',
  'accounts-payable': 'AccountsPayable',
  bills: 'Bills',
  'carriers-accounts': 'CarriersAccounts',
  'customer-carriers-drayage': 'CustomerCarriersDrayage',
  'customer-carriers-freight': 'CustomerCarriersFreight',
  'customer-documents': 'CustomerDocuments',
  'customer-invoices': 'CustomerInvoices',
  'customer-items': 'CustomerItems',
  'customer-payments': 'CustomerPayments',
  'customer-orders': 'CustomerOrders',
  'customer-reseller-bills': 'CustomerResellerBills',
  customers: 'Customers',
  'drayage-jobs': 'DrayageJobs',
  'drayage-job-services-report': 'DrayageJobServicesReport',
  'driver-check-in': 'DriverCheckIn',
  'employee-activity-report': 'EmployeeActivityReport',
  'employee-activity-type-report': 'EmployeeActivityTypeReport',
  'freight-dispatching-services-report': 'FreightDispatchingServicesReport',
  'fulfilled-services': 'FulfilledServices',
  'fulfilled-services-report': 'FulfilledServicesReport',
  invoices: 'Invoices',
  'inventory-stock-quick-view': 'InventoryStockQuickView',
  'inventory-stock-details': 'InventoryStockDetails',
  'locations-inventory': 'LocationsInventory',
  'label-purchasing-services-report': 'LabelPurchasingServicesReport',
  'material-customers-report': 'MaterialCustomersReport',
  'material-details-report': 'MaterialDetailsReport',
  'material-report': 'MaterialReport',
  'material-per-category-report': 'MaterialPerCategoryReport',
  'material-per-customer-report': 'MaterialPerCustomerReport',
  material_products: 'MaterialProducts',
  'material-vendors-report': 'MaterialVendorsReport',
  'order-fulfillment-employee-report': 'OrderFulfillmentEmployeeReport',
  'order-fulfillment-services-report': 'OrderFulfillmentServicesReport',
  orders: 'Orders',
  'purchased-labels': 'PurchasedLabels',
  receipts: 'Receipts',
  'reseller-services-report': 'ResellerServicesReport',
  'services-fees-category-report': 'ServicesFeesCategoryReport',
  'services-fees-category-type-report': 'ServicesFeesCategoryTypeReport',
  'services-fees-report': 'ServicesFeesReport',
  'services-fees-types-report': 'ServicesFeesTypesReport',
  'services-fees-types-warehouse-report': 'ServicesFeesTypesWarehouseReport',
  'services-fulfilled-services-report': 'ServicesFulfilledServicesReport',
  shipments: 'Shipments',
  'special-projects-services-report': 'SpecialProjectsServicesReport',
  'stock-item-history': 'StockItemHistory',
  'order-physical-items-report': 'FulfillmentPhysicalItemsReport',
  'order-items-report': 'FulfillmentItemsReport',
  'order-late-orders-report': 'FulfillmentLateOrdersReport',
  'order-same-day-report': 'FulfillmentSameDayReport',
  terminals: 'Terminals',
  'trucking-jobs': 'TruckingJobs',
  'trucking-jobs-services-report': 'TruckingJobsServicesReport',
  users_customers: 'UsersCustomers',
  users_internal: 'UsersInternal',
  vendors: 'Vendors',
  warehouses: 'Warehouses',
} satisfies Record<string, ListType>;

export const fulfillmentTypeCategories = {
  SMALL_PARCEL: 'SmallParcel',
  FREIGHT: 'Freight',
};

export const fulfillmentTypeCategoriesTypes = [
  {
    id: fulfillmentTypeCategories.SMALL_PARCEL,
    name: 'Small parcel',
    enumInt: 0,
    enumTechValue: fulfillmentTypeCategories.SMALL_PARCEL,
  },
  {
    id: fulfillmentTypeCategories.FREIGHT,
    name: 'Freight',
    enumInt: 1,
    enumTechValue: fulfillmentTypeCategories.FREIGHT,
  },
];

export const carrierAccountOwner = {
  TPL: 'Tpl',
  CUSTOMER: 'TplCustomer',
  RESELLER: 'CustomerReseller',
} satisfies Record<string, CarrierAccountOwnerType>;

export const printLabelTypes = {
  PACKAGE: 'Package',
  ITEM: 'Item',
  PALLET: 'Pallet',
  MOVEABLE_UNIT: 'MoveableUnit',
} satisfies Record<string, ReceiptPrintLabelsType>;

export const printLabelPrefixes = {
  Package: 'P-',
  Item: 'I-',
  Pallet: 'L-',
  MoveableUnit: 'M-',
} satisfies Record<ReceiptPrintLabelsType, string>;

export const MoveableUnitStatusTypes = {
  PREPPING: 'Prepping',
  PREPPED: 'Prepped',
  VERIFIED: 'Verified',
  TRANSPORTING: 'Transporting',
  TRANSPORTED: 'Transported',
  PUTTING_AWAY: 'PuttingAway',
  STORED: 'Stored',
} satisfies Record<string, MoveableUnitStatusType>;

export const appointmentFulfillmentTypes = {
  FTL: 'FTL',
  LTL: 'LTL',
  DRAYAGE: 'Drayage',
} satisfies Record<string, AppointmentFulfillmentType>;

export const warehouseAssetConditionTypes = {
  GOOD: 'Good',
  BAD: 'Bad',
} satisfies Record<string, WarehouseAssetConditionType>;

export const appointmentStatusTypes = {
  Pending: 'Pending',
  Scheduled: 'Scheduled',
  Arrived: 'Arrived',
  Processed: 'Processed',
  Completed: 'Completed',
  Canceled: 'Canceled',
  Empty: 'Empty',
} satisfies Record<string, AppointmentStatusType>;

export const appointmentOpenStatuses: Array<AppointmentStatusType> = [
  'Pending',
  'Scheduled',
  'Arrived',
  'Processed',
];

export const taxEntityTypes = {
  RECIPIENT: 'Recipient',
  SHIPPER: 'Shipper',
};
