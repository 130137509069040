import React, { Fragment, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  DialogProps,
  PaperProps,
} from '@mui/material';
import { ManagerLogin } from '../ManagerLogin';
import { MouseChangeEventType } from '../../../types';
// import { useStyles } from './modals.styles';

interface Props extends Omit<DialogProps, 'title'> {
  open: boolean;
  text?: string | React.ReactElement<any>;
  title?: string | React.ReactElement<any>;
  managerLoginTitle?: string;
  handleClose: (
    event?: object,
    reason?: 'backdropClick' | 'escapeKeyDown',
  ) => void;
  callback?: (e?: MouseChangeEventType) => void;
  btnText?: string;
  paperProps?: Partial<PaperProps<React.ElementType<any>>>;
  cancelBtnText?: string;
  disableCallback?: boolean;
  showManagerLogin?: boolean;
  onAdminLogin?: () => void;
  disableScrollLock?: boolean;
  hideCloseBtn?: boolean;
  submitting?: boolean;
}

export function BasicDialog({
  open,
  text,
  title,
  managerLoginTitle = 'Manager login required to complete edits',
  handleClose: _handleClose,
  callback,
  btnText = 'Ok',
  cancelBtnText = 'Cancel',
  paperProps,
  maxWidth,
  children,
  disableCallback,
  showManagerLogin,
  onAdminLogin,
  disableScrollLock = true,
  hideCloseBtn,
  submitting,
  ...rest
}: Props) {
  // const { classes } = useStyles();
  const handleClose = useCallback(
    (event?: object, reason?: 'backdropClick' | 'escapeKeyDown') => {
      if (hideCloseBtn && reason === 'backdropClick') {
        return;
      }
      _handleClose(event, reason);
    },
    [_handleClose, hideCloseBtn],
  );

  const handleCancel = useCallback(
    (e: MouseChangeEventType) => handleClose(),
    [handleClose],
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      fullWidth
      maxWidth={maxWidth}
      slotProps={{ paper: paperProps }}
      disableScrollLock={disableScrollLock}
      {...rest}
    >
      {showManagerLogin ? (
        <Fragment>
          <DialogTitle>{managerLoginTitle}</DialogTitle>
          <ManagerLogin handleNext={onAdminLogin} handleCancel={handleClose} />
        </Fragment>
      ) : (
        <Fragment>
          {title && <DialogTitle>{title}</DialogTitle>}
          <DialogContent>
            {!!text && (
              <DialogContentText color='textPrimary'>{text}</DialogContentText>
            )}
            {!!children && children}
          </DialogContent>
          <DialogActions>
            {!!callback && (
              <Button
                onClick={callback}
                color='primary'
                disabled={disableCallback}
                loading={submitting}
                loadingPosition='end'
              >
                {btnText}
              </Button>
            )}
            {!hideCloseBtn && (
              <Button color='primary' onClick={handleCancel}>
                {cancelBtnText}
              </Button>
            )}
          </DialogActions>
        </Fragment>
      )}
    </Dialog>
  );
}
