import { useCallback, useState, useEffect, Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { RadioInput, SelectInput, TextInput } from '../inputs';
import {
  useDispatch,
  useSelector,
  updateResource,
  getCustomerSettings,
  systemSelectors,
} from '../../../state';
import { convertNullFieldsToEmptyString } from '../../../lib';
import { InfoMessage } from '../../../components';
import { useGetUsersData, useRefresh } from '../../../hooks';

const accountBalanceTypes = {
  PRE_PAY: 'Prepay',
  CREDIT_LIMIT: 'CreditLimit',
};

const initialState = {
  paymentMethod: '',
  paymentTerm: '',
  invoiceFrequency: '',
  purchaseLabelsInvoiceFrequency: '',
  shippingSelectionPreference: '',
  defaultPickAndStickMode: 'no',
  defaultShipsWholePallet: 'no',
  canScanAndAddInBulk: 'no',
  directiveOverrideMaxWeight: '',
  directiveOverrideMaxHeight: '',
  prepayRequired: 'no',
  sendPastDueAlert: 'yes',
  autoCharge: 'no',
  canViewAccountingDetails: 'no',
  accountBalanceType: accountBalanceTypes.PRE_PAY,
  minBalanceRequired: '',
  minReplenishAmount: '',
  taxEntity: '',
};

export function BasicCustomerSettings({
  open,
  handleClose,
  customerId,
  setViewVersion,
  selectedTab,
}) {
  const { classes, cx, css } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const { isCustomer } = useGetUsersData();

  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);

  const paymentMethodTypes = useSelector(systemSelectors.paymentMethodTypes);

  const shippingSelectionPreferenceTypes = useSelector(
    systemSelectors.shippingSelectionPreferenceTypes,
  );

  const paymentTermTypes = useSelector(systemSelectors.paymentTermTypes);

  const invoiceFrequencyTypes = useSelector(
    systemSelectors.invoiceFrequencyTypes,
  );

  const taxEntityTypes = useSelector(systemSelectors.taxEntityType);

  useEffect(() => {
    (async function () {
      const { data } = await dispatch(getCustomerSettings(customerId));
      if (data) {
        setState((cur) => ({
          ...cur,
          ...convertNullFieldsToEmptyString(data),
          defaultPickAndStickMode: data.defaultPickAndStickMode ? 'yes' : 'no',
          defaultShipsWholePallet: data.defaultShipsWholePallet ? 'yes' : 'no',
          canScanAndAddInBulk: data.canScanAndAddInBulk ? 'yes' : 'no',
          prepayRequired: data.prepayRequired ? 'yes' : 'no',
          sendPastDueAlert: data.sendPastDueAlert ? 'yes' : 'no',
          autoCharge: data.autoCharge ? 'yes' : 'no',
          canViewAccountingDetails: data.canViewAccountingDetails
            ? 'yes'
            : 'no',
          accountBalanceType:
            data.accountBalanceType ?? accountBalanceTypes.PRE_PAY,
        }));
      }
    })();
  }, [customerId, dispatch]);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const payload = {
        ...values,
        defaultPickAndStickMode: values.defaultPickAndStickMode === 'yes',
        defaultShipsWholePallet: values.defaultShipsWholePallet === 'yes',
        canScanAndAddInBulk: values.canScanAndAddInBulk === 'yes',
        prepayRequired: values.prepayRequired === 'yes',
        sendPastDueAlert: values.sendPastDueAlert === 'yes',
        autoCharge: values.autoCharge === 'yes',
        canViewAccountingDetails: values.canViewAccountingDetails === 'yes',
      };
      const { error } = await dispatch(
        updateResource({
          baseUrl: `/customers/${customerId}/basic-settings`,
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        setViewVersion((cur) => ++cur);
        !isCustomer && refresh();
        handleClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerId, dispatch],
  );

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Update basic settings</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 486 } }}
      submitting={submitting}
    >
      {isCustomer || selectedTab === 'customer-accounting' ? (
        <SelectInput
          name='paymentMethod'
          formikProps={formik}
          options={paymentMethodTypes}
          label='Payment method'
          className={classes.input}
          clearable
        />
      ) : (
        <Fragment>
          <SelectInput
            name='paymentTerm'
            formikProps={formik}
            options={paymentTermTypes}
            label='Payment terms'
            className={classes.input}
            clearable
          />
          <div className={classes.sectionTitle}>Account replenishment</div>
          <div className={classes.replenishContainer}>
            <InfoMessage message='When the account reaches the minimum balance amount, the account must be replenished.' />
            <div>
              <RadioInput
                formikProps={formik}
                name='accountBalanceType'
                radiosRow
                title='Account limit balance type'
                titleClassName={css({ fontSize: 12, fontWeight: 500 })}
                size='small'
                radios={[
                  {
                    label: 'Prepay (credit balance)',
                    value: accountBalanceTypes.PRE_PAY,
                  },
                  {
                    label: 'Credit limit (debit balance)',
                    value: accountBalanceTypes.CREDIT_LIMIT,
                  },
                ]}
              />
            </div>
            <div className={classes.replenishTextFields}>
              <TextInput
                name='minBalanceRequired'
                formikProps={formik}
                label={
                  formik.values.accountBalanceType ===
                  accountBalanceTypes.PRE_PAY
                    ? 'Prepay - account limit minimum balance'
                    : 'Credit limit - account limit min balance amount'
                }
                className={classes.input}
                format='currency'
              />
              <TextInput
                name='minReplenishAmount'
                formikProps={formik}
                label='Replenish amount'
                className={classes.input}
                format='currency'
              />
            </div>
          </div>
          <InfoMessage
            message='Changes on the invoice frequency is not cumulative, it will not affect
            retroactive non-invoiced chargeable fees'
          />
          <SelectInput
            name='invoiceFrequency'
            formikProps={formik}
            options={invoiceFrequencyTypes}
            label='Invoice frequency'
            className={classes.input}
            clearable
          />
          <SelectInput
            name='purchaseLabelsInvoiceFrequency'
            formikProps={formik}
            options={invoiceFrequencyTypes}
            label='Purchase labels invoice frequency'
            className={classes.input}
            clearable
          />
          <SelectInput
            name='shippingSelectionPreference'
            formikProps={formik}
            options={shippingSelectionPreferenceTypes}
            label='Preferred shipping method'
            className={classes.input}
            clearable
          />
          <SelectInput
            name='taxEntity'
            formikProps={formik}
            options={taxEntityTypes}
            label='International tax entity type'
            className={classes.input}
            clearable
          />
          <div className={classes.radioInput}>
            <RadioInput
              formikProps={formik}
              name='defaultPickAndStickMode'
              radiosRow
              title='Global pick and stick mode'
              size='small'
              radios={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
              ]}
            />
          </div>
          <div className={classes.radioInput}>
            <RadioInput
              formikProps={formik}
              name='defaultShipsWholePallet'
              radiosRow
              title='Pallets ship as received'
              size='small'
              radios={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
              ]}
            />
          </div>
          <div className={classes.radioInput}>
            <RadioInput
              formikProps={formik}
              name='canScanAndAddInBulk'
              radiosRow
              title='Can scan and add in bulk'
              size='small'
              radios={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
              ]}
            />
          </div>
          <div className={classes.inputRow}>
            <TextInput
              name='directiveOverrideMaxHeight'
              formikProps={formik}
              label='Directive max height'
              className={cx(classes.input, css({ marginRight: 24 }))}
              type='number'
            />
            <TextInput
              name='directiveOverrideMaxWeight'
              formikProps={formik}
              label='Directive max weight'
              className={classes.input}
              type='number'
            />
          </div>
          <div className={classes.radioInput}>
            <RadioInput
              formikProps={formik}
              name='prepayRequired'
              radiosRow
              title='Prepay trucking & drayage'
              size='small'
              radios={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
              ]}
            />
          </div>
          <div className={classes.radioInput}>
            <RadioInput
              formikProps={formik}
              name='autoCharge'
              radiosRow
              title={
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ marginBottom: 8 }}>Auto charge</div>
                  <InfoMessage message=' A default payment method is necessary' />
                </div>
              }
              size='small'
              radios={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
              ]}
            />
          </div>
          <div className={classes.radioInput}>
            <RadioInput
              formikProps={formik}
              name='canViewAccountingDetails'
              radiosRow
              title='Reseller - can view account details'
              size='small'
              radios={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
              ]}
            />
          </div>
          <div className={classes.radioInput}>
            <RadioInput
              formikProps={formik}
              name='sendPastDueAlert'
              radiosRow
              title='Send due invoice alert'
              size='small'
              radios={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
              ]}
            />
          </div>
        </Fragment>
      )}
    </FormModal>
  );
}

const useStyles = makeStyles({ name: { BasicCustomerSettings } })((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  sectionTitle: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  input: {
    marginBottom: 16,
    width: '100%',
  },
  radioInput: {
    marginTop: 23,
  },
  replenishContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  replenishTextFields: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputRow: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
}));
